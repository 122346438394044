import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { Box, Typography, CircularProgress, List, ListItem } from '@mui/material';
import ChatInput from './ChatInput';

const ChatBox = ({ sessionId, pdfFile }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchMessages = useCallback(async () => {
    const token = localStorage.getItem('access_token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/conversations/${sessionId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMessages(response.data);

      const latestMessage = response.data[response.data.length - 1];
      if (latestMessage && latestMessage.sender === 'user') {
        await queryAI(latestMessage.content, pdfFile);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError('Failed to fetch messages.');
    }
  }, [sessionId, pdfFile]);

  useEffect(() => {
    if (sessionId) {
      fetchMessages();
    }
  }, [sessionId, fetchMessages]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async (inputMessage, file = pdfFile) => {
    if (!inputMessage.trim() && !file) return;

    const userMessage = { session_id: sessionId, content: inputMessage, sender: 'user' };
    setMessages((prev) => [...prev, userMessage]);
    await saveMessage(userMessage);

    await queryAI(inputMessage, file);
  };

  const queryAI = async (question, file) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('question', question);
      formData.append('session_id', sessionId);

      if (file instanceof File) {
        formData.append('file', file);
      }

      const token = localStorage.getItem('access_token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/rag/`, formData, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
      });

      const aiMessage = {
        session_id: sessionId,
        content: response.data.response?.content?.content || 'No valid response from AI.',
        sender: 'AI',
      };

      setMessages((prev) => [...prev, aiMessage]);
      await saveMessage(aiMessage);
    } catch (error) {
      console.error('Error fetching AI response:', error);
      setError('Failed to fetch AI response.');
    } finally {
      setLoading(false);
    }
  };

  const saveMessage = async (message) => {
    const token = localStorage.getItem('access_token');
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/conversations/`, message, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      });
    } catch (error) {
      console.error('Error saving message:', error);
      setError('Failed to save the message.');
    }
  };

  const renderMessageContent = (content) => {
    const lines = content.split('\n');
    const isBulletOrNumbered = /^\d+\.\s|^\*\s|^-\s/;

    return (
      <Box component="ol" sx={{ paddingLeft: '1.5rem', margin: 0, listStyleType: 'decimal', listStylePosition: 'inside' }}>
        {lines.map((line, index) => {
          if (isBulletOrNumbered.test(line)) {
            const [title, ...descriptionParts] = line.split(/:\s(.+)/);
            const description = descriptionParts.join(': ');

            return (
              <Box key={index} component="li" sx={{ marginBottom: '1rem', display: 'list-item' }}>
                <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline' }}>
                  {title.replace(/^\d+\.\s|^\*\s|^-\s/, '')}
                </Typography>
                <Typography sx={{ fontSize: 'var(--FontsizeTiny)', color: 'black', whiteSpace: 'pre-line', marginTop: '4px', paddingLeft: '1.5rem' }}>
                  {description}
                </Typography>
              </Box>
            );
          } else {
            return (
              <Typography key={index} sx={{ marginBottom: '1rem', fontSize: 'var(--FontsizeTiny)', color: 'black' }}>
                {line}
              </Typography>
            );
          }
        })}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',  
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
        gap: '16px',
        borderRadius: '16px',
        boxShadow: '0px 4px 8px -2px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box',
        overflow: 'hidden',
        margin: '0 auto'
      }}
    >
  
      {/* Messages List */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: '16px', maxWidth: '800px', margin: '0 auto' }}>
        <List>
          {messages.map((msg, index) => (
            <ListItem
              key={index}
              sx={{
                maxWidth: '768px',
                width: 'fit-content',
                borderRadius: '8px',
                padding: 1.5,
                marginBottom: 1.5,
                alignSelf: msg.sender === 'AI' ? 'flex-start' : 'flex-end',
                background: msg.sender === 'AI'
                  ? '#EFF1FD'
                  : 'linear-gradient(180deg, rgba(225, 183, 87, 0) -17.45%, rgba(225, 183, 87, 0.08) 100%)',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                textAlign: 'left',
                ml: msg.sender === 'AI' ? '10px' : 'auto',
                mr: msg.sender === 'AI' ? 'auto' : '10px',
              }}
            >
              {msg.sender === 'AI' ? (
                renderMessageContent(msg.content)
              ) : (
                <Typography
                  sx={{
                    fontFamily: 'var(--FontfamilyBody)',
                    fontSize: 'var(--FontsizeTiny)',
                    fontWeight: 400,
                    lineHeight: 'var(--LineheightTiny)',
                    color: 'black',
                  }}
                >
                  {msg.content}
                </Typography>
              )}
            </ListItem>
          ))}
          {loading && <CircularProgress />}
          {error && <Typography color="error">{error}</Typography>}
          <div ref={messageEndRef}></div>
        </List>
      </Box>

      {/* Chat Input */}
      <ChatInput onSend={handleSend} />
    </Box>
  );
};

export default ChatBox;
