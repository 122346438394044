import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const WelcomePage = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f5f5f5' }}>
      <Paper elevation={3} sx={{ maxWidth: 500, padding: 4, textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>Welcome to Our App</Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          Please log in or sign up to get started.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => navigate('/login')}
          sx={{ mb: 2 }}
        >
          Login
        </Button>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => navigate('/signup')}
        >
          Sign Up
        </Button>
      </Paper>
    </Box>
  );
};

export default WelcomePage;
